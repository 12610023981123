'use strict';
jQuery(document).ready(function($){

      function load_js()
      {
        var head= document.getElementsByTagName('head')[0];
        var script= document.createElement('script');
        script.src= './assets/js/dist/script.min.js';
        head.appendChild(script);
      }

      $(window).keydown(function(event){
        if(event.keyCode == 13) {
          event.preventDefault();
          return false;
        }
      });

      $('#select_language_nl').on('click', function(){
        $('#language_bar').hide();
        $('#login_form_nl').show();
      });

      $('#select_language_en').on('click', function(){
        $('#language_bar').hide();
        $('#login_form_en').show();
      });
    
      $('#login_form_nl')
      // to prevent form from submitting upon successful validation
      .on("submit", function(ev) {
          ev.preventDefault();
          $('#result-text_nl').removeClass('invalid');
          $('#result-text_nl').text('');
            $('#login_form_nl button').attr('disabled', 'true');
                
                      var that = $('#login_form_nl'),
                      url = that.attr("action"),
                      method = that.attr("method"),
                      data = {};
                      that.find("[name]").each( function() {
                          var that = $(this),
                              name = that.attr("name"),
                              value = that.val();
                              data[name] = value;
                      });
                      
                      $.ajax({
                          url: url,
                          type: method,
                          data: data,
                          success: function(response) {
                            
                              if(response){
                              
                              let result = JSON.parse(response);
                            
                                  if(result.length > 0){
                                      $('#main-content').replaceWith(result[0].info);
                                      load_js();
                                  } else {
                                      $('#result-text').addClass('invalid');
                                      $('#result-text').text('Uw wachtwoord is niet geldig');
                                  }
                              } else {
                              $('#result-text').addClass('invalid');
                              $('#result-text').text('Uw wachtwoord is niet geldig')
                              }
                            
                              $('#login_form_nl button').removeAttr('disabled');
                          },
                          error: function(response){ 
                            
                              console.log(response);
                              $('#login_form_nl button').removeAttr('disabled');
                          }

                      });
                
      });
      $('#login_form_en')
      // to prevent form from submitting upon successful validation
      .on("submit", function(ev) {
          ev.preventDefault();
          $('#result-text').removeClass('invalid');
          $('#result-text').text('');
            $('#login_form_en button').attr('disabled', 'true');
                
                      var that = $('#login_form_en'),
                      url = that.attr("action"),
                      method = that.attr("method"),
                      data = {};
                      that.find("[name]").each( function() {
                          var that = $(this),
                              name = that.attr("name"),
                              value = that.val();
                              data[name] = value;
                      });
                      
                      $.ajax({
                          url: url,
                          type: method,
                          data: data,
                          success: function(response) {
                            
                              if(response){
                              
                              let result = JSON.parse(response);
                            
                                  if(result.length > 0){
                                      $('#main-content').replaceWith(result[0].info);
                                      load_js();
                                  } else {
                                      $('#result-text').addClass('invalid');
                                      $('#result-text').text('Your password is not valid');
                                  }
                              } else {
                              $('#result-text').addClass('invalid');
                              $('#result-text').text('Your password is not valid')
                              }
                            
                              $('#login_form_en button').removeAttr('disabled');
                          },
                          error: function(response){ 
                            
                              console.log(response);
                              $('#login_form_en button').removeAttr('disabled');
                          }

                      });
                
      });

      //CONFIRM DAY 1 FORM
      $('#add-participant-1').on('click', function(){
        var lang = $(this).data('lang');
        var firstnamePlaceholder = 'Voornaam';
        var lastnamePlaceholder = 'Achternaam';
        if(lang == 'en'){
          firstnamePlaceholder = 'First name';
          lastnamePlaceholder = 'Last name';
        }
        $('#participant-list-1').append(`
            <div class="flex flex-wrap mt-2 participant justify-center">
                <input type="text" required class="firstname font-light appearance-none mb-2 lg:mb-0 shadow-sm relative block w-full lg:w-5/12 px-3 py-2 border border-gray-400 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-accent focus:border-accent focus:z-10 sm:text-sm rounded-md md:rounded-r-none" placeholder="`+firstnamePlaceholder+`">
                <input type="text" required class="lastname font-light appearance-none shadow-sm relative block w-full lg:w-5/12 px-3 py-2 border border-gray-400 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-accent focus:border-accent focus:z-10 sm:text-sm rounded-md md:rounded-l-none" placeholder="`+lastnamePlaceholder+`"> 
                <svg xmlns="http://www.w3.org/2000/svg" class="remove-participant self-center cursor-pointer mt-2 lg:mt-0 h-7 lg:h-5 w-full lg:w-2/12 text-red-300 hover:text-red-900" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </div>`);

          if($('#participant-list-1 .participant').length > 1){
            $('#add-partner').hide();
          } else {
            $('#add-partner').show();
          }

         $('#participant-list-1 .remove-participant').on('click', function(){
            $(this).parent().remove();
            if($('#participant-list-1 .participant').length > 1){
              $('#add-partner').hide();
            } else {
              $('#add-partner').show();
            }
          })
      });

      $('#register_form_1')
      // to prevent form from submitting upon successful validation
      .on("submit", function(ev) {
          ev.preventDefault();
          $('#success-message-1').hide();
          $('#error-message-1').text('');
          $('#register_form_1 button').attr('disabled', 'true');
          var that = $('#register_form_1'),
          url = that.attr("action"),
          method = that.attr("method"),
          data = {};


          that.find("[name]").each( function() {
              var that = $(this),
                  name = that.attr("name"),
                  value = that.val();
                  data[name] = value;
          });

          var participants = [];
         $('#participant-list-1 .participant').each(function( index ) {
            var firstname = $(this).find('.firstname').val();
            var lastname = $(this).find('.lastname').val();
            participants.push({firstname,lastname})
         });
         data.participants = participants;

          $('#fail-message-1').text('');

          $.ajax({
            url: url,
            type: method,
            data: data,
            success: function(response) {
                if(response == 'success') {
                  $('#success-message-1').show();
                  if(data.status == 'Aanwezig'){
                    $('#extra-info').show();
                  }
                  $('#register_form_1').hide();
                } else {
                  $('#register_form_1 button').attr('disabled', false);
                  $('#fail-message-1').text('Mislukt, probeer nogmaals.');
                }
              },
              error: function(response){
                $('#register_form_1 button').attr('disabled', false);
                $('#error-message-1').text('Mislukt, probeer nogmaals.');
                console.log(response);
              }

          });
     
        
      });

      $('#status')
      // to prevent form from submitting upon successful validation
      .on("change", function() {
          if($(this).is(":checked")){
            $('#message').show();
            $('#attending-form').hide();
            $(this).val('Afwezig');
          } else {
            $('#message').hide();
            $('#attending-form').show();
            $(this).val('Aanwezig');
            $("#register_form_1").trigger('reset');
          }
      });


      //SHUTTLE FORM
      $('#shuttle_form')
      // to prevent form from submitting upon successful validation
      .on("submit", function(ev) {
          ev.preventDefault();
          $('#shuttle-success').hide();
          $('#shuttle-fail').text('');
          $('#shuttle_form button').attr('disabled', 'true');
          var that = $('#shuttle_form'),
          url = that.attr("action"),
          method = that.attr("method"),
          data = {};


          that.find("[name]").each( function() {
              var that = $(this),
                  name = that.attr("name"),
                  value = that.val();
                  data[name] = value;
          });

          
          $.ajax({
              url: url,
              type: method,
              data: data,
              success: function(response) {
                console.log(response);
                  if(response == 'success') {
                    $('#shuttle-success').show();
                    $('#shuttle_form').hide();
                  } else {
                    $('#shuttle_form button').attr('disabled', 'false');
                    $('#shuttle_form').text('Mislukt, probeer nogmaals');
                  }
              },
              error: function(response){
                $('#shuttle_form button').attr('disabled', 'false');
                $('#shuttle-fail').text('Mislukt, probeer nogmaals.');
                console.log(response);
              }
          });
      });
    });